import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as SignatureIcon } from "../signature_white.svg"; // Importing the SVG file

function MySvgSignature() {
  return (
    <SvgIcon
      component={SignatureIcon}
      viewBox="0 0 202.00941 104.72408"
      sx={{ width: "80px", height: "50px"}} // Set desired size here
    />
  );
}

export default MySvgSignature;
