import React, { useState, useEffect } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  Chip,
  IconButton,
  Button,
  LinearProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import LaunchIcon from "@mui/icons-material/Launch";
import GitHubIcon from "@mui/icons-material/GitHub";

const GitHubRepos = ({ username }) => {
  const [repos, setRepos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchRepos = async () => {
      try {
        const response = await fetch(
          `https://api.github.com/users/${username}/repos`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch repositories");
        }
        const data = await response.json();

        // Sort repositories by creation date (newest first)
        const sortedRepos = data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        // Slice the array to only get the top 10 repositories
        const topRepos = sortedRepos.slice(0, 10);

        setRepos(topRepos);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRepos();
  }, [username]);

  if (loading) {
    return <LinearProgress sx={{ my: 1 }} />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const formatRepoName = (name) => {
    return name
      .replace(/[^a-zA-Z0-9 ]/g, " ") // Replace special characters with spaces
      .split(" ") // Split into words
      .filter(Boolean) // Remove empty strings
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
      .join(" "); // Join back into a single string
  };

  return (
    <Paper sx={{ my: 1, p: 2, overflowX: "hidden" }}>
      <Button
        href="https://github.com/Brianhulela"
        target="_blank"
        rel="noopener noreferrer"
        startIcon={<GitHubIcon />}
        size="large"
        variant="contained"
      >
        GitHub Projects
      </Button>
      <Box
        sx={(theme) => ({
          display: "flex",
          overflowX: "auto",
          gap: 2,
          py: 2,
          scrollBehavior: "smooth", // Smooth scrolling
          "::-webkit-scrollbar": {
            height: 10, // Adjust the height of the scrollbar
          },
          "::-webkit-scrollbar-thumb": {
            background: theme.palette.primary.main, // Use the primary color from the theme
            borderRadius: 4,
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: theme.palette.primary.dark, // Darker primary color on hover
          },
          "::-webkit-scrollbar-track": {
            background: theme.palette.background.default, // Background color from the theme
            borderRadius: 4,
          },
        })}
      >
        {repos.map((repo) => (
          <Card
            elevation={2}
            key={repo.id}
            sx={{
              minWidth: 300,
              maxWidth: 300,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {formatRepoName(repo.name || "No description provided.")}
                </Typography>
                <IconButton
                  href={repo.html_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LaunchIcon />
                </IconButton>
              </Box>

              <Typography
                variant="overline"
                display="block"
                color="textSecondary"
              >
                {new Date(repo.updated_at).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
              </Typography>

              <Chip
                size="small"
                label={repo.language || "No language provided."}
              />
            </CardContent>
          </Card>
        ))}

<Box sx={{display: "flex", alignItems: "center"}}>
        <Button
          href="https://github.com/Brianhulela"
          target="_blank"
          rel="noopener noreferrer"
          variant="outlined"
          endIcon={<LaunchIcon />}
          sx={{ minWidth: "150px" }}
        >
          View More
        </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default GitHubRepos;
