import React from "react";
import AppBarComponent from "../components/AppBarComponent";
import Hero from "../components/Hero";
import { Container, Slide, Fade, Grow } from "@mui/material";
import GitHubRepos from "../components/GitHubRepos";
import MediumArticles from "../components/MediumArticles";
import SubstackArticles from "../components/SubstackArticles";
import BackgroundInfo from "../components/BackgroundInfo";
import Footer from "../components/Footer";

function Home() {
  const username = "brianhulela";

  return (
    <Container maxWidth="lg">
      {/* AppBar Slide Animation */}
      <Slide in={true} direction="down" timeout={600}>
        <div>
          <AppBarComponent />
        </div>
      </Slide>

      {/* Hero Section with Grow Animation */}
      <Grow in={true} timeout={800}>
        <div>
          <Hero />
        </div>
      </Grow>

      {/* Background Info with Fade Animation */}
      <Fade in={true} timeout={1000}>
        <div>
          <BackgroundInfo />
        </div>
      </Fade>

      {/* Substack Articles with Slide Up Animation */}
      <Slide in={true} direction="up" timeout={1200}>
        <div>
          <SubstackArticles username={username} />
        </div>
      </Slide>

      {/* Medium Articles with Staggered Fade Animation */}
      <Fade in={true} timeout={1400}>
        <div>
          <MediumArticles username={username} />
        </div>
      </Fade>

      {/* GitHub Repos with Slide Up Animation */}
      <Slide in={true} direction="up" timeout={1600}>
        <div>
          <GitHubRepos username={username} />
        </div>
      </Slide>

      {/* Footer with Slow Fade Animation */}
      <Fade in={true} timeout={2000}>
        <div>
          <Footer />
        </div>
      </Fade>
    </Container>
  );
}

export default Home;
