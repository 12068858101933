import React, { useRef, useEffect, useState } from "react";
import { Paper, Typography, Box } from "@mui/material";
import InfoCard from "./InfoCard";

function BackgroundInfo() {
  const [activeCardId, setActiveCardId] = useState(null);

  // List of information to be rendered
  const infos = [
    {
      id: 1,
      content: (
        <>
          Hi, I'm <strong>Brian Hulela</strong>. I hold a BSc in Computer
          Science and Applied Mathematics, and a BSc Honours in Big Data
          Analytics, both from the University of the Witwatersrand. I’m
          deeply passionate about <strong>Machine Learning</strong>,{" "}
          <strong>Artificial Intelligence</strong>, <strong>Cloud Computing</strong> and{" "}
          <strong>data-driven Software Development</strong>. These fields
          inspire me to create innovative solutions powered by data.
        </>
      ),
    },
    {
      id: 2,
      content: (
        <>
          Beyond coding and algorithms, I channel my creativity into writing
          on platforms like <strong>Medium</strong> and{" "}
          <strong>Substack</strong>. I also enjoy Digital Art as a hobby. My
          love for technology extends to solving real-world problems,
          especially in <strong>Finance</strong>, where I focus on personal
          finance solutions, as well as exploring how technology can
          transform <strong>Agriculture</strong> and{" "}
          <strong>Education</strong>.
        </>
      ),
    },
    {
      id: 3,
      content: (
        <>
          Collaboration fuels my work. I thrive in environments where I can
          work with others to build impactful projects. I'm always on the
          lookout for driven individuals to collaborate on ideas that
          push boundaries and challenge norms.
        </>
      ),
    },
    {
      id: 4,
      content: (
        <>
          I’m a proud <strong>Tech Enthusiast</strong>, constantly seeking
          new challenges that redefine how we live, work, and define
          success. If you're passionate about groundbreaking ideas and
          collaborative problem-solving, let's connect!
        </>
      ),
    },
  ];

  const cardRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      let closestId = null;
      let closestDistance = Infinity;
  
      cardRefs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const centerY = window.innerHeight / 2;
          const distance = Math.abs(rect.top + rect.height / 2 - centerY);
  
          if (distance < closestDistance) {
            closestDistance = distance;
            closestId = index + 1; // Match `info.id` which starts at 1
          }
        }
      });
  
      setActiveCardId(closestId);
    };
  
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call to set the closest card
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        p: 2,
        my: 1,
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        color="primary"
        sx={{
          fontWeight: "bold",
          mb: 2,
        }}
      >
        About Me
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%",
        }}
      >
        {infos.map((info, index) => (
          <Box
            ref={(el) => (cardRefs.current[index] = el)}
            key={info.id}
            data-id={info.id}
          >
            <InfoCard key={info.id} info={info.content} isActive={activeCardId === info.id} />
          </Box>
        ))}
      </Box>
    </Paper>
  );
}

export default BackgroundInfo;
