import React, { useState, useEffect } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
  Chip,
  Button,
  CardActions,
  LinearProgress,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import MediumIcon from "./MediumIcon";

const MediumArticles = ({ username }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(
          `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@${username}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch Medium articles.");
        }
        const data = await response.json();
        setArticles(data.items || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [username]);

  if (loading) {
    return <LinearProgress sx={{my: 1}}/>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Paper sx={{ my: 1, p: 2 }}>
      <Button
        href="https://medium.com/@brianhulela"
        target="_blank"
        rel="noopener noreferrer"
        size="large"
        variant="contained"
        startIcon={<MediumIcon />}
      >
        Medium Articles
      </Button>
      <Box
        sx={(theme) => ({
          display: "flex",
          overflowX: "auto",
          gap: 2,
          py: 2,
          scrollBehavior: "smooth",
          "::-webkit-scrollbar": {
            height: 10, // Adjust the height of the scrollbar
          },
          "::-webkit-scrollbar-thumb": {
            background: theme.palette.primary.main, // Use the primary color from the theme
            borderRadius: 4,
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: theme.palette.primary.dark, // Darker primary color on hover
          },
          "::-webkit-scrollbar-track": {
            background: theme.palette.background.default, // Background color from the theme
            borderRadius: 4,
          },
        })}
      >
        {articles.map((article) => (
          <Card
            key={article.guid}
            elevation={2}
            sx={{
              minWidth: 300,
              maxWidth: 300,
              flex: "0 0 auto", // Prevent shrinking
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Card Media */}
            {article.content.match(/<img src="(.*?)"/) && (
              <CardMedia
                component="img"
                height="140"
                image={article.content.match(/<img src="(.*?)"/)[1]} // Extract the first image URL from content
                alt={article.title}
              />
            )}

            <CardContent>
              {/* Title */}
              <Typography variant="h6" gutterBottom>
                {article.title}
              </Typography>

              <Typography variant="overline" color="textSecondary" sx={{ mb: 2 }}>
                {new Date(article.pubDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
              </Typography>

              {/* Categories */}
              {article.categories && article.categories.length > 0 && (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {article.categories.map((category, index) => (
                    <Chip
                      key={index}
                      label={category}
                      variant="outlined"
                      size="small"
                      color="primary"
                    />
                  ))}
                </Box>
              )}
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
                size="small"
                endIcon={<LaunchIcon />}
                sx={{
                    backgroundColor: "transparent", // Remove the contained background
                    color: "primary.light", // Set text color to primary
                    "&:hover": {
                      backgroundColor: "primary.dark", // Add a light primary background on hover
                      color: "primary.contrastText", // Adjust text color for better contrast
                    },
                  }}
              >
                Read More
              </Button>
            </CardActions>
          </Card>
        ))}
        <Box sx={{display: "flex", alignItems: "center"}}>
        <Button
          variant="outlined"
          href="https://medium.com/@brianhulela"
          target="_blank"
          rel="noopener noreferrer"
          endIcon={<LaunchIcon />}
          sx={{ minWidth: "150px" }}
        >
          View More
        </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default MediumArticles;
