import { Box, CssBaseline } from "@mui/material";
import Home from "./pages/Home";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#386641",
    },
    secondary: {
      main: "#bc4749",
    },
    background: {
      // black background
      default: "#000000",
      paper: "#01110a",
    },
    text: {
      primary: "#bdbdbd"
    }
  },
  typography: {
    fontFamily: "'IBM Plex Mono', monospace", // Ensure consistency in font across the theme
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box>
        <Home />
      </Box>
    </ThemeProvider>
  );
}

export default App;
