import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as MediumIconSVG } from "../medium.svg"; // Importing the SVG file

function MediumIcon() {
  return (
    <SvgIcon
      component={MediumIconSVG}
      viewBox="0 -55 256 256"
      sx={{ width: "24px", height: "24px"}} // Set desired size here
    />
  );
}

export default MediumIcon;
