import React from "react";
import { Box, Typography, Paper, Chip } from "@mui/material";
import TypedBox from "./TypedBox";

function Hero() {
  const keywords = [
    "Machine Learning",
    "Artificial Intelligence",
    "Cloud Computing",
    "Data Science",
    "Software Development",
    "Mathematics",
    "Autonomous Systems",
    "Personal Finance",
    "Agriculture",
    "Africa",
    "Education",
    "Collaboration",
    "Problem-Solving",
    "Digital Art",
    "Blogging",
  ];

  return (
    <Paper
      sx={{
        minHeight: "50vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        p: 4,
        my: 1,
      }}
    >
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Brian Hulela
      </Typography>

      <TypedBox/>

      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          justifyContent: "center",
        }}
      >
        {keywords.map((keyword, index) => (
          <Chip
            key={index}
            size="small"
            label={keyword}
            color="primary"
            variant="outlined"
          />
        ))}
      </Box>
    </Paper>
  );
}

export default Hero;
