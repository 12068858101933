import React from "react";
import { Box, Card, CardContent, Divider, Typography, useTheme } from "@mui/material";

function InfoCard({ info, isActive }) {
  const theme = useTheme(); // Access the MUI theme

  return (
    <Card elevation={2} sx={{ borderRadius: "16px" }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Divider
            sx={{
              borderWidth: 4,
              width: "100px",
              borderRadius: "8px",
              backgroundColor: isActive
                ? theme.palette.secondary.main
                : theme.palette.divider,
            }}
          />
        </Box>

        <Typography variant="body1">{info}</Typography>
      </CardContent>
    </Card>
  );
}

export default InfoCard;
