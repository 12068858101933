import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import MySvgSignature from "./MySvgSignature";
import ContactForm from "./ContactForm";
import PictureAvatar from "./PictureAvatar";

function AppBarComponent() {
  return (
    <AppBar position="static" color="transparent">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              mr: 2,
              flexGrow: 1,
            }}
          >
            <MySvgSignature />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ContactForm />
            <PictureAvatar />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AppBarComponent;
