import React from "react";
import { Box, Typography, Link } from "@mui/material";
import MediumIcon from "./MediumIcon";
import GitHubIcon from "@mui/icons-material/GitHub";
import SubstackIcon from "./SubstackIcon";
import XIcon from '@mui/icons-material/X';

function Footer() {
  return (
    <Box
      sx={{
        p: 2,
        my: 1,
        textAlign: "center",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          mb: 2,
          fontSize: "0.875rem",
          fontWeight: 500,
          letterSpacing: "0.5px",
        }}
      >
        © {new Date().getFullYear()} Brian Hulela. All Rights Reserved.
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <Link
          href="https://medium.com/@brianhulela"
          target="_blank"
          rel="noopener"
          underline="none"
          color="inherit"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            "&:hover": { color: "lightblue" },
          }}
        >
          <MediumIcon sx={{ fontSize: 24 }} />
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            Medium
          </Typography>
        </Link>

        <Link
          href="https://substack.com/@brianhulela"
          target="_blank"
          rel="noopener"
          underline="none"
          color="inherit"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            "&:hover": { color: "lightblue" },
          }}
        >
          <SubstackIcon sx={{ fontSize: 24 }} />
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            Substack
          </Typography>
        </Link>

        <Link
          href="https://github.com/brianhulela"
          target="_blank"
          rel="noopener"
          underline="none"
          color="inherit"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            "&:hover": { color: "lightblue" },
          }}
        >
          <GitHubIcon sx={{ fontSize: 24 }} />
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            GitHub
          </Typography>
        </Link>
        
        <Link
          href="https://x.com/BrianHulela"
          target="_blank"
          rel="noopener"
          underline="none"
          color="inherit"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            "&:hover": { color: "lightblue" },
          }}
        >
          <XIcon sx={{ fontSize: 24 }} />
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            X
          </Typography>
        </Link>
      </Box>

      <Typography
        variant="body2"
        sx={{
          fontSize: "0.75rem",
          color: "#bbb",
        }}
      >
        Built with ❤️ and passion for innovation.
      </Typography>
    </Box>
  );
}

export default Footer;
