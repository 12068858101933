import React, { useState, useEffect } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
  Button,
  CardActions,
  LinearProgress,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import SubstackIcon from "./SubstackIcon";

const SubstackArticles = ({ username }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(
          `https://api.rss2json.com/v1/api.json?rss_url=https://${username}.substack.com/feed`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch Substack articles.");
        }
        const data = await response.json();
        setArticles(data.items.slice(0, 10)); // Get the top 10 articles
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [username]);

  if (loading) {
    return <LinearProgress sx={{my: 1}}/>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Paper sx={{my: 1, p: 2 }}>
      <Button
        href={`https://${username}.substack.com/`}
        target="_blank"
        rel="noopener noreferrer"
        size="large"
        variant="contained"
        startIcon={<SubstackIcon />}
      >
        Substack Newsletters
      </Button>
      <Box
        sx={(theme) => ({
          display: "flex",
          overflowX: "auto",
          gap: 2,
          py: 2,
          scrollBehavior: "smooth",
          "::-webkit-scrollbar": {
            height: 10,
          },
          "::-webkit-scrollbar-thumb": {
            background: theme.palette.primary.main,
            borderRadius: 4,
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: theme.palette.primary.dark,
          },
          "::-webkit-scrollbar-track": {
            background: theme.palette.background.default,
            borderRadius: 4,
          },
        })}
      >
        {articles.map((article) => (
          <Card
            key={article.guid}
            elevation={3}
            sx={{
              minWidth: 300,
              maxWidth: 300,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Featured Image */}
            {article.enclosure?.link && (
              <CardMedia
                component="img"
                height="150"
                image={article.enclosure.link}
                alt={article.title}
              />
            )}
            <CardContent>
              {/* Title */}
              <Typography variant="h6" gutterBottom>
                {article.title}
              </Typography>

              {/* Author */}
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ display: "block" }}
              >
                {`By ${article.author} from The Afro Code`}
              </Typography>

              {/* Date */}
              <Typography
                variant="overline"
                color="textSecondary"
                sx={{ display: "block" }}
              >
                {new Date(article.pubDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ mt: 1, mb: 2 }}
              >
                {article.description.length > 100
                  ? `${article.description.substring(0, 100)}...`
                  : article.description}
              </Typography>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
              {/* Read More Button */}
              <Button
                size="small"
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<LaunchIcon />}
                sx={{
                  backgroundColor: "transparent", // Remove the contained background
                  color: "primary.light", // Set text color to primary
                  "&:hover": {
                    backgroundColor: "primary.dark", // Add a light primary background on hover
                    color: "primary.contrastText", // Adjust text color for better contrast
                  },
                }}
              >
                Read More
              </Button>
            </CardActions>
          </Card>
        ))}
        <Box sx={{display: "flex", alignItems: "center"}}>
        <Button
          variant="outlined"
          href={`https://${username}.substack.com/`}
          target="_blank"
          rel="noopener noreferrer"
          endIcon={<LaunchIcon />}
          sx={{ minWidth: "150px" }}
        >
          View More
        </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default SubstackArticles;
